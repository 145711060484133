/* eslint-disable consistent-return */
/* eslint-disable no-console */
import apiConfig from "../configs/apiConfig";
import ApiService from "./ApiService";
import rollbar from "../utilities/error-logger";

interface ISessionInfo {
    id: string;
    sessionData: string;
    returnUrl?: string;
    recurringProcessingModel?:string;
}

const getReturnUrl = async (merchantReference, authResult, paymentMethod) => {
    const bodyObj = {
        merchantReference: merchantReference || authResult.merchantReference,
        authResult: authResult.resultCode,
        paymentMethod: authResult.additionalData?.paymentMethod || authResult.paymentMethod?.brand || authResult.paymentMethod?.type || paymentMethod,
        pspReference: authResult.pspReference
    }

    const body = JSON.stringify(bodyObj);

    const response = await ApiService.CallApi<ISessionInfo>({
        url: apiConfig.endpoints.paymentGateway.getReturnUrl,
        data: body,
        method: "POST"
    });

    rollbar.info("Getting return url", { merchantReference: merchantReference, correlationId: response.headers["x-correlation-id"] });
    return response.data;
};

const cancelPayment = async (merchantReference) => {
    const bodyObj = {
        merchantReference: merchantReference,
        authResult: "Cancelled",
        paymentMethod: ""
    }

    const body = JSON.stringify(bodyObj);

    const response = await ApiService.CallApi<ISessionInfo>({
        url: apiConfig.endpoints.paymentGateway.getReturnUrl,
        data: body,
        method: "POST"
    });

    rollbar.info("Cancelling payment", { merchantReference: merchantReference, correlationId: response.headers["x-correlation-id"] });
    return response.data;
};

const sessionPayment = async (countryCode, currencyCode, merchantAccount, merchantReference, shopperReference, paymentAmount, shopperLocale, shopperEmail, merchantSig, sessionValidity, skinCode, recurringContract, skipSelection) => {
    const body = JSON.stringify({
        countryCode: countryCode,
        currencyCode: currencyCode,
        merchantAccount: merchantAccount,
        merchantReference: merchantReference,
        merchantSig: merchantSig,
        paymentAmount: paymentAmount,
        recurringContract: recurringContract,
        sessionValidity: sessionValidity,
        shopperEmail: shopperEmail,
        shopperLocale: shopperLocale,
        shopperReference: shopperReference,
        skinCode: skinCode,
        skipSelection: skipSelection
    });

    debugger;

    const response = await ApiService.CallApi<ISessionInfo>({
        url: apiConfig.endpoints.paymentGateway.paymentSessions,
        data: body,
        method: "POST"
    });
    rollbar.info("Creating payment session", { merchantReference: merchantReference, correlationId: response.headers["x-correlation-id"] });
    return response.data;
}

const disableStoredPayment = async (merchantReference, shopperReference, storedPaymentMethodId, merchantAccount) => {
    const body = JSON.stringify({
        shopperReference: shopperReference,
        recurringDetailReference: storedPaymentMethodId,
        merchantReference: merchantReference,
        merchantAccount: merchantAccount
    });

    const response = await ApiService.CallApi<any>({
        url: apiConfig.endpoints.paymentGateway.disableStoredPayment,
        data: body,
        method: "POST"
    });
    rollbar.info("Removing payment method", { storedPaymentMethodId: storedPaymentMethodId, correlationId: response.headers["x-correlation-id"] });
    return response.data;
}

const submitPayment = async (shopperReference, shopperEmail, merchantReference, merchantAccount, paymentAmount, currencyCode, data, checkout, recurringProcessingModel, recurringContract) => {
    const body = JSON.stringify({
        merchantAccount: merchantAccount,
        shopperReference: shopperReference,
        shopperEmail: shopperEmail,
        reference: merchantReference,
        amount: {
            value: paymentAmount,
            currency: currencyCode
        },
        paymentMethod: data.paymentMethod,
        browserInfo: data.browserInfo,
        authenticationData: {
            threeDSRequestData: {
                nativeThreeDS: "Preferred"
            }
        },
        channel: "Web",
        origin: window.location.origin,
        storePaymentMethod: data.storePaymentMethod || recurringContract?.includes("RECURRING"),
        returnUrl: process.env.RETURN_URL || checkout.options.returnUrl,
        recurringProcessingModel: recurringProcessingModel
    })

    const response = await ApiService.CallApi<any>({
        url: apiConfig.endpoints.paymentGateway.submitPayment,
        data: body,
        method: "POST"
    });
    rollbar.info("Submitting payment", { merchantAccount: merchantAccount, correlationId: response.headers["x-correlation-id"] });

    return response.data;

}

const getAdditionalDetails = async (state) => {
    const response = await ApiService.CallApi<any>({
        url: apiConfig.endpoints.paymentGateway.paymentDetails,
        data: JSON.stringify(state.data),
        method: "POST"
    });
    rollbar.info("Getting additional payment details",  { correlationId: response.headers["x-correlation-id"] });
    return response.data;
}

export {sessionPayment, getReturnUrl, disableStoredPayment, submitPayment, getAdditionalDetails, cancelPayment};
